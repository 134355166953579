import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import './font.css';
import 'leaflet/dist/leaflet.css';
import { useMap, LayersControl, GeoJSON, MapContainer, TileLayer } from 'react-leaflet';
import asianJsonData from './maps/asian.json';
import hispanicJsonData from './maps/hispanic.json';
import neighborhoodJsonData from './maps/neighborhoods.json';
import { BsFillPinMapFill } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";


function App() {
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [selectedTerritory, setSelectedTerritory] = useState(null);
  const [selectedPopulationRace, setSelectedPopulationRace] = useState(null);
  const [selectedPopulationSize, setSelectedPopulationSize] = useState(null);
  const [selectedLanguageOneName, setSelectedLanguageOneName] = useState(null);
  const [selectedLanguageOneSize, setSelectedLanguageOneSize] = useState(null);
  const [selectedLanguageTwoName, setSelectedLanguageTwoName] = useState(null);
  const [selectedLanguageTwoSize, setSelectedLanguageTwoSize] = useState(null);

  const [fillOpacity, setFillOpacity] = useState(0.5); 

  const handleButtonClick = () => {
    setIsChecked(!isChecked);
    if (isChecked2) {
      setIsChecked2(false);
      setSelectedTerritory(null);
    }
  };
  

  const handleButtonClick2 = () => {
    setIsChecked2(!isChecked2);
    if (isChecked) {
      setIsChecked(false);
      setSelectedTerritory(null);
    }
  };

  const handleSliderChange = (event) => {
    const opacityValue = parseFloat(event.target.value);
    setFillOpacity(opacityValue);
  };

  function ChangeFontSizeOnZoom() {
    const map = useMap();
  
    useEffect(() => {
      function handleZoom() {
        const currentZoom = map.getZoom();
  
        // Calculate the font size based on the current zoom level
        const fontSize = (currentZoom-4) + 'px';
        console.log(currentZoom);
  
        // Get the region labels and set the font size
        const regionLabels = document.getElementsByClassName('region-label');
        Array.from(regionLabels).forEach((label) => {
          label.style.fontSize = fontSize;
          label.style.display = currentZoom >= 12 ? "block" : "none";
        });
      }
  
      map.on('zoom', handleZoom);
  
      // Cleanup the event listener when the component unmounts
      return () => {
        map.off('zoom', handleZoom);
      };
    }, [map]);
  
    return null;
  }

  useEffect(() => {
    if (!isChecked && !isChecked2) {
      setSelectedTerritory(null); // Set selectedTerritory to null when either dataset is untoggled
    }
  }, [isChecked, isChecked2]);

  const bounds = [
    [39.541825, -86.357691], // Southwest coordinates (latitude, longitude)
    [39.948434, -85.906567]  // Northeast coordinates (latitude, longitude)
  ];

  const mapNames = [
    'Asian',
    'Hispanic',
  ];

  const mapOptions = {
    weight: .5
  };

  const getColor = (feature) => {
    // Modify this function based on the property you want to use for the fill color
    const propertyValue = feature.properties.POP;

    // Define the color based on the property value
    if (propertyValue > 999) {
      return '#ea2222';
    } else if (propertyValue > 499 && propertyValue < 1000) {
      return '#ffa800';
    } else if (propertyValue > 199 && propertyValue < 500) {
      return '#ffff00';
    } else {
      return '#00b050';
    }
  };

  const style = (feature) => {
    return {
      fillColor: getColor(feature),
      fillOpacity: fillOpacity,
      color: '#000',
      weight: mapOptions.weight,
      interactive: true // Enable interactivity
    };
  };

  const onEachFeature = (feature, layer) => {
    layer.on('click', () => {
      const selectedTerritoryName = feature.properties.NAME;
      const selectedPopulationRace = feature.properties.RACE;
      const selectedPopulationSize = feature.properties.POP;
      const selectedLanguageOneName = feature.properties.LANGONE;
      const selectedLanguageOneSize = feature.properties.LANGPOP;
      const selectedLanguageTwoName = feature.properties.LANGTWO;
      const selectedLanguageTwoSize = feature.properties.LANGTWOPOP;

      setSelectedTerritory(selectedTerritoryName);
      setSelectedPopulationSize(selectedPopulationSize);
      setSelectedPopulationRace(selectedPopulationRace);
      setSelectedLanguageOneName(selectedLanguageOneName);
      setSelectedLanguageOneSize(selectedLanguageOneSize);
      setSelectedLanguageTwoName(selectedLanguageTwoName);
      setSelectedLanguageTwoSize(selectedLanguageTwoSize);
    });
  };

  const onMainFeature = (feature, layer) => {
    if (feature.properties.NAME) {
      layer.bindTooltip(feature.properties.NAME, {
        permanent: true,
        direction: 'center',
        className: 'region-label'
      });
    }
  };

  const neighborhoodOverlayRef = useRef(null);

  useEffect(() => {
    if (neighborhoodOverlayRef.current) {
      neighborhoodOverlayRef.current.bringToFront();
    }
  }, [isChecked, isChecked2]);

  return (
    <div className="App">      
    <div className="mapBox">
      <div className="slider-wrapper">
        <div className="dashboard">
          <div className="spacer title-box">
            <h5>Targeted Foreign Language Speakers for SUD Literature Distribution in Marion County: 2023</h5>
          </div>
          <div className="spacer toggle-box">
            <h6>Choose a data set below:</h6>
            <div className={isChecked ? 'toggle selected' : 'toggle'} >
              <span className="toggle-name">{mapNames[0]}</span>
              <label className="switch">
                <input type="checkbox" checked={isChecked} onClick={handleButtonClick} />
                <span className="slider round"></span>
              </label>
            </div>

            <div className={isChecked2 ? 'toggle selected' : 'toggle'}>
              <span className="toggle-name">{mapNames[1]}</span>
              <label className="switch">
                <input type="checkbox" checked={isChecked2} onClick={handleButtonClick2} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="data-box">
              <p><a href="https://data.census.gov/table?t=Hispanic+or+Latino&g=050XX00US18097$1400000&tid=DECENNIALPL2020.P2" target="_blank" rel="noopener noreferrer">[?] learn more about this data</a>.</p>
            </div>
            
            <div className="slider-container">
              <h6>Adjust Heatmap Opacity</h6>
              <input
                type="range"
                min="0"
                max="1"
                step="0.2"
                value={fillOpacity}
                onChange={handleSliderChange}
              />
            </div>

            <div className="key-box">
              <h6>Linguistic Demographic Density Index</h6>
              <div className="key-colors">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="key-label">
                <div>Low</div>
                <div>High</div>
              </div>
            </div>
          </div>
        </div>


        <div className={(isChecked || isChecked2) && selectedTerritory ? "spacer profile" : "spacer profile hidden"}>
          
          <h3>Region Profile</h3>
          <hr />
          {selectedTerritory && (
            <>
            <div className="profile-item">
              <div>
                <BsFillPinMapFill />
                <h5>Census Tract:</h5>
              </div>
              <span>{selectedTerritory}</span>
            </div>
            <hr />
            </>
          )}
          {selectedPopulationSize && (
            <>
            <div className="profile-item">
              <div>
                <BsFillPeopleFill />
                <h5>{selectedPopulationRace} Population:</h5>
              </div>
              <span>{selectedPopulationSize}</span>
            </div>
            <hr />
            </>
          )}
          <h5 className="sub-text">Foreign Speakers At Home</h5>
          <hr />
          {selectedLanguageOneName && (
            <>
            <div className="profile-item">
              <div>
                <BsFillPeopleFill />
                <h5>{selectedLanguageOneName} Language:</h5>
              </div>
              <span>{selectedLanguageOneSize}</span>
            </div>
            <hr />
            </>
          )}
          {selectedLanguageTwoName && (
            <>
            <div className="profile-item">
              <div>
                <BsFillPeopleFill />
                <h5>{selectedLanguageTwoName} Speakers:</h5>
              </div>
              <span>{selectedLanguageTwoSize}</span>
            </div>
            <hr />
            </>
          )}
        </div>
      </div>

        <MapContainer
          center={[39.768333, -86.158350]} // Center of Marion County
          zoom={10}
          scrollWheelZoom={true}
          className="map-container"
          maxBounds={bounds} // Set the boundary for panning
          maxBoundsViscosity={1.0} // Adjust how restrictive the boundary is (optional)
          minZoom={10} // Set the minimum allowed zoom level
          maxZoom={18} // Set the maximum allowed zoom level
        >
          <ChangeFontSizeOnZoom />
            <LayersControl position="topright">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <LayersControl.Overlay name={mapNames[2]} checked>
              <GeoJSON data={neighborhoodJsonData} style={{ fillColor: "none", color: "#000" }} onEachFeature={onMainFeature} ref={neighborhoodOverlayRef} />
            </LayersControl.Overlay>
            <LayersControl.Overlay name={mapNames[0]} checked={isChecked}>
              <GeoJSON data={asianJsonData} style={style} onEachFeature={onEachFeature} />
            </LayersControl.Overlay>
            <LayersControl.Overlay name={mapNames[1]} checked={isChecked2}>
              <GeoJSON data={hispanicJsonData} style={style} onEachFeature={onEachFeature} />
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
      </div>
    </div>
  );
}

export default App;
